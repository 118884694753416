import { NgModule } from '@angular/core';
import { ActivatedRoute, RouterModule, Routes } from '@angular/router';
import { LayoutTemplateComponent } from './template/common/layout/layout-template/layout-template.component';
import { LinkTwoComponent } from './template/link-two/link-two.component';
import { LoginComponent } from './login/login.component';
import { SitesComponent } from './sites/sites.component';
import { SiteSetupComponent } from './sites/site-setup/site-setup.component';
import { SiteSetupPreviewComponent } from './sites/site-setup/site-setup-preview/site-setup-preview.component';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { AuthGuardService } from './services/auth/auth-guard.service';
import { ReportsComponent } from './reports/reports.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { OpenPOReportsComponent } from './open-poreports/open-poreports.component';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { LogoutComponent } from './logout/logout.component';
import { AuditReportsComponent } from './audit-reports/audit-reports.component';
import { AuthGuard } from './guard/auth-guard.guard';
export const routes: Routes = [
  {
    path: '',
    component: LayoutTemplateComponent,
    pathMatch: 'full',
    children: [{ path: '', component: LoginComponent }],
    data: { showHeader: false, breadcrumb: 'Login' }
  },
  {
    path: 'sites',
    component: LayoutTemplateComponent,
    canActivate:[OktaAuthGuard, AuthGuard],
    children: [
      { path: '', component: SitesComponent, data: { breadcrumb: 'Sites' } },
      { path: 'site-setup', component: SiteSetupComponent, data: { breadcrumb: 'Create Site' } },
      { path: 'site-setup-preview/:siteCode', component: SiteSetupPreviewComponent, data: { breadcrumb: 'Site Setup Preview' } }
    ],
    data: { breadcrumb: 'Sites' }
  },
  {
    path: 'user-management',
    component: LayoutTemplateComponent,
    canActivate:[OktaAuthGuard, AuthGuard],
    children: [{ path: '', component: UserManagementComponent }],
    data: { breadcrumb: 'User Management' }
  },
  {
    path: 'reports',
    component: LayoutTemplateComponent,
    canActivate: [OktaAuthGuard, AuthGuard],
    //canActivate: [AuthGuardService],
    children: [
      { path: '', component: ReportsComponent, data: { breadcrumb: 'IOH Reports' } },
    ],
    data: { breadcrumb: 'Reports' }
  },
  {
    path: 'poreports',
    component: LayoutTemplateComponent,
    canActivate:[OktaAuthGuard, AuthGuard],
    //canActivate: [AuthGuardService],
    children: [
      { path: '', component: OpenPOReportsComponent, data: { breadcrumb: 'poreports' } },
    ],
    data: { breadcrumb: 'POReports' }
  },
  {
    path: 'auditreports',
    component: LayoutTemplateComponent,
    canActivate:[OktaAuthGuard],
    children: [
      {
        path: '',
        component: AuditReportsComponent,
        data: {
          breadcrumb: ''
        }
      },
    ]
  },
  {
    path: 'auth/login',
    component: OktaCallbackComponent
  },
  {
    path: 'auth/logout',
    component: LogoutComponent
  },
  {
    path: 'access-denied',
    component: AccessDeniedComponent
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
