export type ReportType = 'ParItem' | 'ItemExpirationReport' | 'CountSubmissionReport' | 'MOMReport' | 'OOSReport';

export const HEADER_MAPPINGS: { [key in ReportType]: string[] } = {
    ParItem: [
        'Image', 'Description', 'Lot Tracked', 'Status', 'UOM', 'Modified By', 'Pending Order',
        'Items', 'Modified', 'Created Date', 'IOH Qty', 'Ordering Qty', 'Optimal Qty',
        'Site Id', 'Department Id', 'Item Id'
    ],
    ItemExpirationReport: [
        'Item Id', 'Site Id', 'Department Id', 'Description', 'Lot#',
        'Lot Expiration Date', 'Lot IOH Qty', 'Days to Expiration Date'
    ],
    OOSReport: [
        'Item Id', 'Site Id', 'Department Id', 'Description', 'IOH Quantity', 'Usage',
        'Days To OOS', 'OOS Date', 'Order Actions', 'Last Modified Date'
    ],
    CountSubmissionReport: [
        '# of unique active items submitted', '# of unique active items in par file',
        'Site Id', 'Department Id', 'Percent Submitted'
    ],
    MOMReport: [
        'Year', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
        'Site Id', 'Department Id'
    ]
};

export const PROPERTY_MAPPINGS: { [key: string]: string } = {
    'Image': 'image',
    'Description': 'description',
    'Lot Tracked': 'lottracked',
    'Status': 'status',
    'UOM': 'uom',
    'Modified By': 'modifiedby',
    'Pending Order': 'pendingorder',
    'Items': 'items',
    'Modified': 'modified',
    'Created Date': 'createddate',
    'IOH Qty': 'iohqty',
    'Ordering Qty': 'orderingqty',
    'Optimal Qty': 'optimalqty',
    'Site Id': 'siteid',
    'Department Id': 'departmentid',
    'Item Id': 'itemid',
    'Usage': 'usage',
    'Days to OOS': 'daystooos',
    'OOS Date': 'oosdate',
    'Order Actions': 'orderactions',
    'IOH Quantity': 'iohquantity',
    'Last Modified Date': 'lastmodifieddate',
    '# of unique active items submitted': 'itemssubmitted',
    '# of unique active items in par file': 'itemsinparfile',
    'Percentage Submitted': 'percentsubmitted',
    'Lot#': 'lot',
    'Lot Expiration Date': 'expiry',
    'Lot IOH Qty': 'quantity',
    'Days to Expiration Date': 'daysuntilexpiry'
};
