import { Injectable } from '@angular/core';
import { ReportType, HEADER_MAPPINGS, PROPERTY_MAPPINGS } from '../../global/report-constants';
import { auditReportItemRows } from '../../model/auditReport.model';

@Injectable({
  providedIn: 'root'
})
export class ReportUtilityServiceService {

  constructor() { }
  getMappedHeaders(reportType: ReportType): string[] {
    return HEADER_MAPPINGS[reportType] || [];
  }

  // mapToAuditReportRows(records: any[]): auditReportItemRows[] {
  //   return records.map(item => {
  //     const mappedItem: auditReportItemRows = {} as auditReportItemRows;
  //     Object.keys(item).forEach(key => {
  //       const lowerKey = key.toLowerCase();
  //       const mappedKey = PROPERTY_MAPPINGS[key] || lowerKey;
  //       mappedItem[mappedKey] = item[key]?.toString() || '';
  //     });
  //     return mappedItem;
  //   });
  // }
  mapToAuditReportRows(records : any[]): auditReportItemRows[]{
    console.log(records);
    return records.map(item => {
        const mappedItem: auditReportItemRows = {
          image: item.image || '',
          code: item.code || '',
          quantity: item.quantity?.toString() || '',
          createddate: item.createddate || '',
          desiredquantity: item.desiredQuantity?.toString() || '',
          lottracked: item.lottracked?.toString() || '',
          description: item.description || '',
          pendingorder: item.pendingorder?.toString() || '',
          uom: item.uom || '',
          reorderquantity: item.reorderQuantity?.toString() || '',
          modified: item.modified || '',
          modifiedby: (item.modifiedBy || item.modifiedby || '').toString(),
          items: item.items?.toString() || '',
          status: item.status || '',
          percentsubmitted: item.percentsubmitted || '',
          itemssubmitted: item.itemsSubmitted?.toString() || '',
          itemsuploaded: item.itemsUploaded?.toString() || '',
          daysUntilExpiry: '',
          expiry: '',
          orderyear: item.orderYear?.toString() || '',
          ordermonth: item.orderMonth?.toString() || '',
          itemid: item.itemId || '',
          operatingunit: item.operatingUnit?.toString() || '',
          businessunit: item.businessUnit?.toString() || '',
          department: item.department?.toString() || '',
          orderscount: item.ordersCount?.toString() || '',
          totalquantity: item.totalQuantity?.toString() || '',
          siteid: item.siteId || '',
          departmentid: item.departmentId || '',
        };
        Object.keys(item).forEach(key => {
            if (! mappedItem.hasOwnProperty(key)) {
                const value = item[key];
                mappedItem[key.toLowerCase()] = value ?. toString() || '';
            }
        });
        return mappedItem;
    });
}

  formatCountSubmissionReport(response: any[]): any[] {
    console.log('Processing Count Submission report response:', response);   
  
    const formattedRows: any[] = response.map(item => ({
      itemssubmitted: item['# of unique active items submitted']?.toString() || '',
      itemsinparfile: item['# of unique active items in par file']?.toString() || '',
      siteid: item.siteId || '',
      departmentid: item.departmentId || '',
      itemid: item.itemId || '',
      percentsubmitted: this.calculatePercentSubmitted(
        item['# of unique active items submitted'], 
        item['# of unique active items in par file']
      )
    } as any));
  
    console.log('Final formatted Count Submission rows:', formattedRows);
    return formattedRows;
  }
  private calculatePercentSubmitted(submitted: string, total: string): string {
    const submittedNum = parseInt(submitted, 10);
    const totalNum = parseInt(total, 10);
    if (isNaN(submittedNum) || isNaN(totalNum) || totalNum === 0) return '0%';
    return Math.round((submittedNum / totalNum) * 100) + '%';
  }

  // formatItemExpirationReport(response: any[]): any[] {
  //   return response.flatMap(item => 
  //     (item.items || [])
  //       .filter((subItem: any) => subItem.quantity && subItem.quantity !== 0 && subItem.quantity !== '0')
  //       .map((subItem: any) => ({
  //         itemid: item.itemId || '',
  //         siteid: item.siteId || '',
  //         departmentid: item.departmentId || '',
  //         description: item.description || '',
  //         lot: subItem.lot || '',
  //         quantity: subItem.quantity?.toString() || '',
  //         expiry: subItem.expiry || '',
  //         daysuntilexpiry: subItem.daysUntilExpiry || ''
  //       }))
  //   );
  // }
  formatItemExpirationReport(response: any[]): any[] {
    let formattedRows: any[] = [];    
    console.log('Processing response:', response);   
    response.forEach(item => {
      if (item.items && item.items.length > 0) {
        const nonZeroItems = item.items.filter((subItem: any) => 
          subItem.quantity && subItem.quantity !== 0 && subItem.quantity !== '0'
        );       
        console.log('Non-zero quantity items:', nonZeroItems);
        nonZeroItems.forEach((subItem: any) => {
          const row = {
            itemid: item.itemId || '',
            siteid: item.siteId || '',
            departmentid: item.departmentId || '',
            description: item.description || '',
            lot: subItem.lot || '',
            quantity: subItem.quantity?.toString() || '',
            expiry: subItem.expiry || '',
            daysuntilexpiry: subItem.daysUntilExpiry || ''
          };
          console.log('Adding row:', row);
          formattedRows.push(row);
        });
      }
    });
    console.log('Final formatted rows:', formattedRows);
    return formattedRows;
  }
  getItemValue(item: auditReportItemRows, header: string): string {
    const propertyName = PROPERTY_MAPPINGS[header] || header.toLowerCase().replace(/\s+/g, '');
    const value = item[propertyName as keyof auditReportItemRows];
    if (propertyName === 'items' && Array.isArray(value)) {
      return this.formatItemsForCsv(value);
    }
    return value !== undefined ? value.toString() : '';
  }
  private formatItemsForCsv(items: any[]): string {
    return items.map(item => 
      `[Lot:${item.lot} | Qty:${item.quantity} | Exp:${item.expiry} | days_until_expiry:${item.days_until_expiry}]`
    ).join(' ');
  }
    
  // private formatItemsForCsv(items: any[]): string {
  //   if (this.currentReportType === 'ParItem') {
  //     return items.map(item => 
  //       `[Lot:${item.lot} | Qty:${item.quantity} | Exp:${item.expiry} | days_until_expiry:${item.days_until_expiry} ] `
  //     ).join(' ');
  //   }
  //   return JSON.stringify(items).replace(/"/g, '""');
  // }
  groupRowsByItemId(rows: auditReportItemRows[]): { [key: string]: auditReportItemRows[] } {
    return rows.reduce((acc, item) => {
      if (!acc[item.itemid]) {
        acc[item.itemid] = [];
      }
      acc[item.itemid].push(item);
      return acc;
    }, {} as { [key: string]: auditReportItemRows[] });
  }

  processAuditReport(rows: auditReportItemRows[]): {
    processedRows: auditReportItemRows[],
    groupedItems: { [key: string]: auditReportItemRows[] },
    totalItems: number
  } {
    const groupedByItemId = this.groupRowsByItemId(rows);
    // Sort each group by modified date (newest to oldest) and remove duplicates
    Object.keys(groupedByItemId).forEach(itemId => {
      const group = groupedByItemId[itemId];
      // Remove duplicates using Set
      const uniqueRows = new Set(group.map(row => JSON.stringify(row)));
      const deduplicatedGroup = Array.from(uniqueRows).map(item => JSON.parse(item));
      // Sort by modified date (newest to oldest)
      deduplicatedGroup.sort((a, b) => new Date(b.modified).getTime() - new Date(a.modified).getTime());
      groupedByItemId[itemId] = deduplicatedGroup;
    });
    // // Filter out items with no changes in the selected time frame
    // const filteredGroups = Object.values(groupedByItemId).filter(group => group.length > 1);
    
    // Store the grouped items for the expandable view
    const groupedItems = Object.entries(groupedByItemId).reduce((acc, [itemId, group]) => {
      acc[itemId] = group;
      return acc;
    }, {} as { [key: string]: auditReportItemRows[] });

    // Calculate total items
    const totalItems = Object.values(groupedItems).flat().length;

    // Return flattened data, grouped items, and total items
    return {
      processedRows: Object.values(groupedItems).flat(),
      groupedItems,
      totalItems
    };
  }
}
