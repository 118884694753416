import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { stat } from 'fs';
import { first } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {
  validationPatterns = {
    siteId: '^[a-zA-Z0-9]{6}$',
    siteName: '^(?=.{1,32}$)(?!.*\\s{2})(?![\\W_])[\\w\\s\\-,.()&\\\\]{1,32}(?<![\\W_])$',
    requesterId: '^[a-zA-Z0-9]{0,32}',
    departmentName: '^(?=.{1,32}$)(?!.*\\s{2})(?![\\W_])[\\w\\s\\-,.()&\\\\]{1,32}(?<![\\W_])$',
    departmentCode: '^[0-9]{4}$',
    departmentUsers: '^[a-zA-Z0-9,\\s]+$',
    shipToCode: '^[a-zA-Z0-9]{0,32}',
    location: '^[a-zA-Z0-9]{0,32}',
    firstName: '^[a-zA-Z]{0,32}$',
    lastName: '^[a-zA-Z]{0,32}$',
    itemId: '^[0-9]{18}$',
    modifiedBy: this.modifiedByValidator(),
    businessUnit: '^[a-zA-Z0-9]{5}$',
    glUnit: '^[a-zA-Z0-9]{5}$'
  };

  modifiedByValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const value = control.value;
      if (!value) {
        return null;
      }
      if (value === "System User") {
        return null; 
      }
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (emailRegex.test(value)) {
        return null; 
      }    
      return { 'invalidModifiedBy': true };
    };
  }

  validationMessages = {
    division: {
      required: 'Division is required'
    },
    siteId: {
      required: 'Site ID is required',
      pattern: 'Site ID must be an alphanumeric string with 6 characters only and with no special characters or spaces',
    },
    siteName: {
      required: 'Site Name is required',
      pattern: 'Site Name must be 1-32 characters long, start and end with a letter or number,allowed special characters (- , . ( ) & \ ), without consecutive spaces, leading or trailing special characters.'
    },
    requesterId: {
      required: 'Requester ID is required',
      pattern: 'Requester ID must be an alphanumeric string between 1 and 32 characters with no special characters and space.'
    },
    recountFrequency: {
      required: 'Recount Frequency is required'
    },
    siteStatus: {
      required: 'Site Status is required'
    },
    departmentName: {
      required: 'Department Name is required',
      pattern: 'Department Name must be 1-32 characters long, start and end with a letter or number,allowed special characters (- , . ( ) & \ ), without consecutive spaces, leading or trailing special characters.'
    },
    departmentCode: {
      required: 'Department Code is required',
      pattern: 'Department Code must be a numeric string of 4 digits'
    },
    departmentStatus: {
      required: 'Department Status is required'
    },
    departmentUsers: {
      required: 'Department User(s) is required'
    },
    labStatus: {
      required: 'Department Status is required'
    },
    shipToCode: {
      required: 'Ship To Code is required',
      pattern: 'Ship To Code must be an alphanumeric string between 1 and 32 characters with no special characters and space..'
    },
    location: {
      required: 'Location is required',
      pattern: 'Location must be an alphanumeric string between 1 and 32 characters with no special characters and space..'
    },
    email: {
      required: 'Email is required',
      email: 'Please enter a valid email address'
    },
    role: {
      required: 'Role is required'
    },
    firstName: {
      required: 'First Name is required',
      pattern: 'First Name must be an alphabet string between 1 and 32 characters with no special characters and space.'
    },
    lastName: {
      required: 'Last Name is required',
      pattern: 'Last Name must be an alphabet string between 1 and 32 characters with no special characters and space.'
    },
    site: {
      required: 'Site is required'
    },
    department: {
      required: 'Department is required'
    },
    businessUnit: {
      required: 'Business Unit is required',
      pattern: 'Business Unit must be an alphanumeric string between 1 and 5 characters with no special characters and space.'
    },
    glUnit: {
      required: 'GL Unit is required',
      pattern: 'GL Unit must be an alphanumeric string between 1 and 5 characters with no special characters and space.'
    },
    status: {
      required: 'Status is required'
    },
    itemId: {
      pattern: 'Item-Id must be numeric and exactly 18 digits'
    },
    modifiedBy: {
      invalidModifiedBy: 'Must be a valid email or "System User"'
    }
  };
}
