<div class="mb-4">
    <label [for]="id" class="form-label d-flex align-items-center">
      {{ label }}
      <ng-template #tooltipTemplate>{{ tooltipContent }}</ng-template>
      <span
        class="material-icons-round ms-2 fs-6"
        [ngbPopover]="tooltipTemplate"
        [placement]="'top'"
        [autoClose]="'outside'"
        triggers="hover"
      >
        info_outline
      </span>
    </label>
    
    <ng-select
    [items]="siteOptions"
    bindLabel="text"
    bindValue="value"
    [placeholder]="placeholder"
    [formControl]="formControl"
    [loading]="loading"
    [id]="id"
    (change)="onValueChange($event)"
    (clear)="onClear()"
    [clearable]="true"
    [ngClass]="{'is-invalid': formControl.invalid && formControl.touched}"
  >
  </ng-select>
  
    <div
      *ngIf="formControl?.invalid && formControl?.touched"
      class="invalid-feedback d-flex flex-direction-column mt-2"
      role="alert"
      aria-live="polite"
    >
      <span class="material-icons-round icon-sm me-1">error_outline</span>
      <div *ngIf="formControl?.errors?.['required']">
        {{ validationMessages["required"] }}
      </div>
    </div>
  </div>