<div class="table-responsive col-12">
  <table class="table table-striped">
    <thead>
      <tr>
        <!-- For Par-File Audit Report -->
<ng-container *ngIf="reportType === 'Par-FileAuditReport'; else otherReports">
  <th *ngFor="let header of mainHeaders" 
      [appSortable]="header.key"
      [direction]="property === header.key ? direction : ''"
      (sort)="onSort($event)">
    {{ header.name }}
    @if(property === header.key) {
      <app-sortdirection 
        [property]="property" 
        [direction]="direction">
      </app-sortdirection>
    }
  </th>
  <th *ngIf="isGrouped && hasMultipleItems">Actions</th>
</ng-container>

<!-- For other report types -->
<ng-template #otherReports>
  <th *ngFor="let header of headers" 
        [appSortable]="header.toLowerCase()"
        [direction]="property === header.toLowerCase() ? direction : ''"
            (sort)="onSort($event)">
          {{ header}}
          @if(property === header.toLowerCase()) {
            <app-sortdirection 
              [property]="property" 
              [direction]="direction">
            </app-sortdirection>
          }
        </th>
</ng-template>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="isGrouped; else nonGroupedRows">
        <ng-container *ngFor="let group of paginatedGroups | keyvalue">
          <tr>
            <td *ngFor="let header of reportType === 'Par-FileAuditReport' ? mainHeaders : headers">
              {{ reportType === 'Par-FileAuditReport' ? 
                  getMainHeaderValue(group.value[0], header) : 
                  getItemValue(group.value[0], header) }}
            </td>
            <td *ngIf="hasMultipleItems && reportType === 'Par-FileAuditReport'">
              <button class="btn btn-sm" (click)="toggleExpand(group.key)" *ngIf="group.value.length > 0">
                <span class="material-icons-round">
                  {{ isExpanded(group.key) ? 'remove' : 'add' }}
                </span>
              </button>
            </td>
          </tr>
          <tr *ngIf="isExpanded(group.key) && group.value.length > 0 && reportType === 'Par-FileAuditReport'">
            <td [attr.colspan]="(reportType === 'Par-FileAuditReport' ? mainHeaders.length : headers.length) + (hasMultipleItems ? 1 : 0)">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th *ngFor="let header of expandedHeaders" 
            [appSortable]="header.toLowerCase()"
            [direction]="property === header.toLowerCase() ? direction : ''"
            (sort)="onSort($event)">
          {{ header }}
          @if(property === header.toLowerCase()) {
            <app-sortdirection 
              [property]="property" 
              [direction]="direction">
            </app-sortdirection>
          }
        </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of group.value">
                    <td *ngFor="let header of headers">
                      {{ getItemValue(item, header) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </ng-container>
      </ng-container>
      <ng-template #nonGroupedRows>
        <tr *ngFor="let item of paginatedItems">
          <td *ngFor="let header of headers">
            {{ getItemValue(item, header) }}
          </td>
        </tr>
      </ng-template>
    </tbody>
  </table>
</div>
<div class="d-flex justify-content-end mt-3">
  <app-pagination [totalItems]="totalItems" [pageSize]="pageSize" [currentPage]="currentPage"
    (pageChanged)="onPageChanged($event)">
  </app-pagination>
</div>

