import { Inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { filter, map, Observable, Subscription } from 'rxjs';
import { CustomUserClaim, OktaAuth } from '@okta/okta-auth-js';
import { AuthState } from '@okta/okta-auth-js';
import { TokenStorageService } from '../token-storage/token-storage.service';
@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private apiUrl = environment.backendApiUrl;
  private headers: HttpHeaders;
  email: string = "";
  public isAuthenticated$!: Observable<boolean>;
  authSubscription: Subscription | undefined;
  claims: { claim: string; value: CustomUserClaim | CustomUserClaim[]; }[] | undefined;


  constructor(private http: HttpClient,
    private oktaAuthStateService: OktaAuthStateService,
    private tokenStorage: TokenStorageService,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth
  ) {
    const authToken = this.tokenStorage.getToken();

    this.isAuthenticated$ = this.oktaAuthStateService.authState$.pipe(
      filter((s: AuthState) => !!s),
      map((s: AuthState) => s.isAuthenticated ?? false)
    );
    this.authSubscription = this.isAuthenticated$.subscribe(async (isAuthenticated) => {
      console.log('User authentication status:', isAuthenticated);

      if (isAuthenticated) {
        const token = await this.oktaAuth.getAccessToken() || null;
        const userClaims = await this.oktaAuth.token.getUserInfo();
        this.claims = Object.entries(userClaims).map(entry => ({ claim: entry[0], value: entry[1] }));
        this.email = userClaims['email'] || "";
        console.log(this.email)
      } else {
        // User is not authenticated, perform a different action
        console.log('User is not authenticated');
      }
    });

    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`
    });
  }

  getReports(): Observable<any> {
    return this.http.put(this.apiUrl, {});
  }

  getReportFilterList(email: string): Observable<any> {
    console.log("service-email")
    //const url = `https://am4ztr28mj.execute-api.us-east-1.amazonaws.com/v1/auth/user/check`;
    const url = `${this.apiUrl}/auth/user/${email}`
    return this.http.get(url, { headers: this.headers });
  }
  getReportData(siteCode: string, departmentCode: string): Observable<any> {

    //const url = `https://am4ztr28mj.execute-api.us-east-1.amazonaws.com/v1/auth/user/check`;
    const url = `${this.apiUrl}/par-file/${siteCode}/${departmentCode}`
    return this.http.get(url, { headers: this.headers });
  }
  postAuditReport(siteCode: string, departmentCode: string, reportData: any, reportName: string): Observable<any> {
    const url = `${this.apiUrl}/par-file/${siteCode}/${departmentCode}/report/${reportName}`
    return this.http.post(url, reportData, { headers: this.headers });
  }

}
