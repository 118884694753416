import { Component, Input, forwardRef, OnInit, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-select-dropdown',
  templateUrl: './select-dropdown.component.html',
  styleUrls: ['./select-dropdown.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectDropdownComponent),
      multi: true
    }
  ]
})
export class SelectDropdownComponent implements ControlValueAccessor, OnInit {
  @Input() controlName!: string;
  @Input() id!: string;
  @Input() label!: string;
  @Input() options!: Array<{ value: any, text: string }>;
  @Input() placeholder!: string;
  @Input() tooltipContent!: string;
  @Input() formControl!: FormControl; 
  @Input() validationMessages: { [key: string]: string } = {}; 

  @Output() change = new EventEmitter<any>();

  isTooltipOpen = false;
  onChange: (value: any) => void = () => {};
  onTouched: () => void = () => {};
  form!: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      myControl: ['', Validators.required]
    });
  }

  toggleTooltip() {
    this.isTooltipOpen = !this.isTooltipOpen;
  }

  writeValue(value: any): void {
    if (value !== this.formControl.value) {
      this.formControl.setValue(value, { emitEvent: false });
    }
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  // onValueChange(event: Event) {
  //   if (!this.formControl.disabled) {
  //     let value = (event.target as HTMLSelectElement).value;
  //     value = this.extractStatusFromValue(value);
  //     this.onChange(value);
  //     this.onTouched();
  //     this.change
  //     console.log('Selected value:', value); // Debug line
  //   }
  // }
  onValueChange(event: Event) {
    if (this.formControl && !this.formControl.disabled) {
      let value = (event.target as HTMLSelectElement).value;
      value = this.extractStatusFromValue(value);
      this.onChange(value);
      this.onTouched();
      this.change.emit(value);  // Emit the raw value
      console.log('Selected value:', value);
    }
  }
  private extractStatusFromValue(value: string): string {
    return value.split(':').pop()?.trim() || value;
  }
}
