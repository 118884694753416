import { Component, Input, forwardRef, OnInit, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

interface DropdownOption {
  value: any;
  text: string;
}

@Component({
  selector: 'app-ng-select-dropdown',
  templateUrl: './ng-select-dropdown.component.html',
  styleUrl: './ng-select-dropdown.component.css',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NgSelectDropdownComponent),
      multi: true
    }
  ]
})
export class NgSelectDropdownComponent implements ControlValueAccessor, OnInit {
  @Input() controlName!: string;
  @Input() id!: string;
  @Input() label!: string;
  @Input() siteOptions: DropdownOption[] = []; 
  @Input() placeholder!: string;
  @Input() tooltipContent!: string;
  @Input() formControl!: FormControl;
  @Input() validationMessages: { [key: string]: string } = {};
  @Input() loading: boolean = false;

  @Output() change = new EventEmitter<any>();
  @Output() clear = new EventEmitter<void>();

  isTooltipOpen = false;
  onChange: (value: any) => void = () => {};
  onTouched: () => void = () => {};
  form!: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      myControl: ['', Validators.required]
    });
  }

  toggleTooltip() {
    this.isTooltipOpen = !this.isTooltipOpen;
  }

  writeValue(value: any): void {
    if (value !== this.formControl.value) {
      this.formControl.setValue(value, { emitEvent: false });
    }
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  onValueChange(event: any) {
    if (this.formControl && !this.formControl.disabled) {
      const value = event?.code || event?.value || event;
      this.onChange(value);
      this.onTouched();
      this.change.emit(value);
      console.log('Selected value:', value);
    }
  }
  onClear() {
    this.formControl.setValue(null);
    this.clear.emit();
  }
}
