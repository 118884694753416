import { Component, Input, Output, EventEmitter, forwardRef, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputFieldComponent),
    multi: true
  }]
})
export class InputFieldComponent implements OnInit {
  @Input() controlName!: string;
   @Input() id!: string;
  @Input() label!: string;
  @Input() placeholder!: string;
  @Input() formControl!: FormControl;
  @Input() validationPattern!: string;
  @Input() validationMessages: { [key: string]: string } = {};
  @Input() tooltipContent!: string;
  @Output() ngModelChange: EventEmitter<string> = new EventEmitter<string>();

  isTooltipOpen = false;
  form!: FormGroup;

  toggleTooltip() {
    this.isTooltipOpen = !this.isTooltipOpen;
  }

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      myControl: ['', Validators.required]
    });
  }
  hasError(errorType: string): boolean {
    return this.formControl.hasError(errorType) && (this.formControl.touched || this.formControl.dirty);
  }

  onModelChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.ngModelChange.emit(inputElement.value);
  }
  value: any;
  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // Handle the disabled state if necessary
  }

  // Additional methods to handle the input change
  handleInputChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.value = inputElement.value;
    this.onChange(this.value);
  }
}
