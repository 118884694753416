import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { auditReportItem, auditReportItemApiResponse, auditReportItemHeaders, auditReportItemRows } from '../../model/auditReport.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-audit-report-table',
  templateUrl: './audit-report-table.component.html',
  styleUrl: './audit-report-table.component.css',
})
export class AuditReportTableComponent implements OnChanges {
@Input() auditReportItems: auditReportItem[] = []
@Input() headers: auditReportItemHeaders[] = [];
@Input() rows: auditReportItemRows[] = [];
@Input() groupedItems: { [key: string]: auditReportItemRows[] } = {};
@Input() isGrouped: boolean = false;
@Input() reportType: string = '';
mainHeaders: auditReportItemHeaders[] = [
  { 
    key: 'siteid', 
    name: 'SITE ID', 
    headers: [], 
    toLowerCase: () => 'siteid' 
  },
  { 
    key: 'departmentcode', 
    name: 'DEPARTMENT CODE', 
    headers: [], 
    toLowerCase: () => 'departmentcode' 
  },
  { 
    key: 'itemid',
    name: 'ITEM ID', 
    headers: [], 
    toLowerCase: () => 'itemid' 
  },
  { 
    key: 'description', 
    name: 'DESCRIPTION', 
    headers: [], 
    toLowerCase: () => 'description' 
  }
];
expandedHeaders: auditReportItemHeaders[] = [];

auditReportByPagination: any[] = [];
paginatedItems: auditReportItemRows[] = [];
property: string = '';
direction: string = '';
@Input() siteName: string = '';
@Input() departmentName: string = '';
  expandedItems: { [key: string]: boolean } = {};
  pageSize: number = 10;
  totalUniqueItems = 0;
  currentPage: number = 1;
  totalItems = 0;
  paginatedGroups: { [key: string]: auditReportItemRows[] } = {};
  constructor(
    private route: ActivatedRoute
  ){
    this.route.queryParams.subscribe(params=>{
      this.reportType = params['reportType']
    })
  }
  getItemValue(item: auditReportItemRows, header: string | auditReportItemHeaders): string {
    let key: string;
    if (typeof header === 'string') {
      key = header.toLowerCase().replace(/\s+/g, '');
    } else {
      key = header.key.toLowerCase();
    }
  
    const headerToPropertyMap: { [key: string]: keyof auditReportItemRows } = {
      'image': 'image',
      'description': 'description',
      'lottracked': 'lottracked',
      'status': 'status',
      'uom': 'uom',
      'modifiedby': 'modifiedby',
      'pendingorder': 'pendingorder',
      'items': 'items',
      'modified': 'modified',
      'createddate': 'createddate',
      'iohqty': 'iohqty',
      'orderingqty': 'orderingqty',
      'optimalqty': 'optimalqty',
      'siteid': 'siteid',
      'departmentid': 'departmentid',
      'itemid': 'itemid',
      'lot#': 'lot',
      'lotexpirationdate': 'expiry',
      'lotiohqty': 'quantity',
      'daystoexpirationdate': 'daysuntilexpiry',
      'iohquantity': 'iohquantity',
      'usage': 'usage',
      'daystooos': 'daystooos',
      'oosdate': 'oosdate',
      'orderactions': 'orderactions',
      'lastmodifieddate': 'lastmodifieddate',
      '#ofuniqueactiveitemssubmitted': 'itemssubmitted',
      '#ofuniqueactiveitemsinparfile': 'itemsinparfile',
      'Percentage Submitted': 'percentsubmitted'
    };
  
    const propertyName = headerToPropertyMap[key] || key;
    return item[propertyName as keyof auditReportItemRows]?.toString() || '';
  }
  
  
  toggleExpand(key: string) {
    this.expandedItems[key] = !this.expandedItems[key];
  }
  isExpanded(key: string): boolean {
    return this.expandedItems[key] || false;
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['rows'] || changes['groupedItems'] || changes['reportType'] || changes['isGrouped']) {
      this.setupHeaders();
      this.totalItems = this.isGrouped ? Object.keys(this.groupedItems).length : this.rows.length;
      this.updatePagination();
    }
  }
  setupHeaders() {
    if (this.reportType === 'Par-FileAuditReport') {
      this.expandedHeaders = [...this.headers];
      console.log(this.expandedHeaders);
    } else {
      this.expandedHeaders = this.headers;
    }
  }
  getMainHeaderValue(item: auditReportItemRows, header: auditReportItemHeaders): string {
    switch (header.key) {
      case 'siteid':
        return this.siteName;
      case 'departmentcode':
        return this.departmentName;
      case 'itemid':
        return item.itemid?.toString() || '';
      case 'description':
        return item.description?.toString() || '';
      default:
        return this.getItemValue(item, header);
    }
  }
  onPageChanged(page: number) {
    this.currentPage = page;
    this.updatePagination();
  }
  get hasMultipleItems(): boolean {
    return Object.values(this.groupedItems).some(group => group.length > 0);
  }
  updatePagination() {
    if (this.isGrouped) {
      const allKeys = Object.keys(this.groupedItems);
      const start = (this.currentPage - 1) * this.pageSize;
      const end = Math.min(start + this.pageSize, allKeys.length);
      const paginatedKeys = allKeys.slice(start, end);
      this.paginatedGroups = paginatedKeys.reduce((acc, key) => {
        acc[key] = this.groupedItems[key];
        return acc;
      }, {} as { [key: string]: auditReportItemRows[] });
    } else {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      this.paginatedItems = this.rows.slice(start, end);
      console.log(this.paginatedItems);
    }
  }
  onSort(event: { property: string; direction: string }) {
    this.property = event.property;
    this.direction = event.direction;
    if (this.isGrouped) {
      this.sortGroupedItems();
    } else {
      this.sortRows();
    }
    this.updatePagination();
  }
  private sortRows() {
    console.log('Sorting property:', this.property);
    console.log('Sort direction:', this.direction);
    this.rows.sort((a, b) => {
      const valueA = this.getPropertyValue(a, this.property);
      const valueB = this.getPropertyValue(b, this.property);
      return this.compareValues(valueA, valueB, this.direction === 'desc');
    });
  }
  private sortGroupedItems() {
    console.log('Sorting property:', this.property);
    console.log('Sort direction:', this.direction);
  
    // Step 1: Sort items within each group
    const sortedGroupedItems: { [key: string]: any[] } = {};
    for (const [key, group] of Object.entries(this.groupedItems)) {
      const sortedGroup = group.sort((a, b) => {
        const valueA = this.getPropertyValue(a, this.property);
        const valueB = this.getPropertyValue(b, this.property);
        return this.compareValues(valueA, valueB, this.direction === 'desc');
      });
      sortedGroupedItems[key] = sortedGroup;
    }
  
    // Step 2: Sort the groups themselves
    const sortedEntries = Object.entries(sortedGroupedItems).sort(([keyA, groupA], [keyB, groupB]) => {
      // Compare based on the first item in each group
      const valueA = this.getPropertyValue(groupA[0], this.property);
      const valueB = this.getPropertyValue(groupB[0], this.property);
      return this.compareValues(valueA, valueB, this.direction === 'desc');
    });
  
    // Reconstruct the sorted object
    this.groupedItems = Object.fromEntries(sortedEntries);
    console.log('Sorted groupedItems:', this.groupedItems);
  }
  getPropertyValue(item: any, property: string): any {
    return item[property.toLowerCase()];
  }
  private compareValues(valueA: any, valueB: any, isDesc: boolean): number {
    if (valueA === null || valueA === undefined) return isDesc ? 1 : -1;
    if (valueB === null || valueB === undefined) return isDesc ? -1 : 1;
    let comparison = 0;
    if (typeof valueA === 'string' && typeof valueB === 'string') {
      comparison = valueA.localeCompare(valueB);
    } else if (typeof valueA === 'number' && typeof valueB === 'number') {
      comparison = valueA - valueB;
    } else if (valueA instanceof Date && valueB instanceof Date) {
      comparison = valueA.getTime() - valueB.getTime();
    } else {
      // Handle date strings
      const dateA = new Date(valueA);
      const dateB = new Date(valueB);
      if (!isNaN(dateA.getTime()) && !isNaN(dateB.getTime())) {
        comparison = dateA.getTime() - dateB.getTime();
      } else {
        comparison = String(valueA).localeCompare(String(valueB));
      }
    }
    return isDesc ? -comparison : comparison;
  }
}
