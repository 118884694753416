import { Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Params } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Breadcrumb } from '../model/breadcrumb.model';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrl: './breadcrumb.component.css'
})
export class BreadcrumbComponent implements OnInit {
  breadcrumbs: Breadcrumb[] = [];
  
  constructor(private router: Router, private route: ActivatedRoute) {}
  
  ngOnInit(): void {
    this.breadcrumbs = this.buildBreadcrumbs(this.route.root);
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.breadcrumbs = this.buildBreadcrumbs(this.route.root);
    });
  }

  private buildBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: Breadcrumb[] = []): Breadcrumb[] {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      const label = this.getLabel(child);
      if (label) {
        if (breadcrumbs.length > 0 && breadcrumbs[breadcrumbs.length - 1].label === label) {
          continue;
        }
        breadcrumbs.push({ label, url });
      }

      return this.buildBreadcrumbs(child, url, breadcrumbs);
    }
    return breadcrumbs;
  }

  private getLabel(route: ActivatedRoute): string {
    const breadcrumbData = route.snapshot.data['breadcrumb'];
    
    // If we're on the audit reports route, check for query params
    if (route.snapshot.url.map(segment => segment.path).includes('auditreports')) {
      const reportType = route.snapshot.queryParams['reportType'];
      if (reportType) {
        // Add spaces before capital letters and format the report type
        return this.formatReportType(reportType);
      }
    }

    if (typeof breadcrumbData === 'function') {
      // If breadcrumb is a function, call it with the route
      return breadcrumbData(route.snapshot);
    }

    return breadcrumbData || '';
  }

  private formatReportType(reportType: string): string {
    // Add space before capital letters and trim
    return reportType
      .replace(/([A-Z])/g, ' $1')
      .trim();
  }
}