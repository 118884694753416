export class SiteSetupConstants {
    public static siteInfo = {
        siteTitle: 'Site Information',
        siteSubTitle: 'Please provide site information',
    };
    public static labInfo = {
        LabTitle: 'Lab Supply Department',
        LabSubTitle: 'Please provide Lab Supply Department'
    }
    public static tooltipInfo = {
        DIVISION: 'Select the Division under which site has to be created',
        SITE_ID_TOOLTIP: 'Enter the unique identifier for the site. This ID is used to manage and distinguish different operating Units',
        SITE_NAME_TOOLTIP: 'Enter the name of the site or operating unit',
        REQUESTER_ID_TOOLTIP: 'Enter the ID or username of the person requesting the site setup',
        RECOUNT_FREQUENCY_TOOLTIP: 'Select how often you want the system to recount or reconcile data for this site',
        SITE_STATUS_TOOLTIP: 'Indicate whether this site is currently active or inactive',
        DEPARTMENT_NAME_TOOLTIP: 'Enter the name of the department or division within the site',
        DEPARTMENT_CODE_TOOLTIP: 'Enter a unique code or identifier for the department',
        DEPARTMENT_STATUS_TOOLTIP: 'Indicate whether this department is currently active or inactive',
        DEPARTMENT_USERS_TOOLTIP: 'Enter the usernames or IDs of the users associated with this department',
        FILE_UPLOAD_TOOLTIP: 'Upload Par File in csv Format only and less 1MB',
        SHIP_TO_CODE_TOOLTIP: 'Enter the Ship To Code for the Department',
        LOCATION_TOOLTIP: 'Enter the Location for the Department',
        EMAIL_TOOLTIP: "Provide the user's email address",
        STATUS_TOOLTIP: "Indicate whether the user is Active or Inactive",
        USERNAME_TOOLTIP: "Enter a unique username for the user",
        FIRST_NAME_TOOLTIP: "Enter the user's first name. This is required for identification purposes.",
        LAST_NAME_TOOLTIP: "Enter the user's last name. This is required for identification purposes.",
        DIVISION_TOOLTIP: "Assign the user to a division within the organization. This determines the user's access to specific resources.",
        ROLE_TOOLTIP: "Select the role that defines the user's permissions within the organization.",
        SITES_TOOLTIP: "Assign the user to specific sites or operating units within the organization.",
        DEPARTMENTS_TOOLTIP: "Assign the user to specific departments within a partiuclar site.",
        getSiteTooltip: (reportType: string) => 
            `Select the specific site for which you want to generate the ${reportType}`,
        getDepartmentTooltip: (reportType: string) =>
            `Choose the department within the selected site for which you want to view ${reportType} data.`,
        getDateRangeTooltip: (reportType: string) =>
            `Select the date range of the period you want to view for the ${reportType}. This date must be within 90 days of the start date.`,
        getItemIdTooltip: (reportType: string) =>
            `Enter a specific item ID to filter the ${reportType}. Leave blank to view all items.`,
        getModifiedByTooltip: (reportType: string) =>
            `Enter a username to filter the ${reportType} by who made the changes. Leave blank to view changes by all users.`,
        BUSINESSUNIT_TOOLTIP: "Enter the Business Unit for this site.",
        GLUNIT_TOOLTIP: "Enter the GL Unit for this site."
    }
    public static fileUpload = {
        FILE_UPLOAD_TTILE: 'Upload Par File',
        FILE_UPLOAD_HEADING: 'Select a File or drag and drop here',
        FILE_UPLOAD_DESCRIPTION: 'CSV file size should not exceed 1MB.',
        FILE_UPLOAD_BUTTON_LABEL: 'Select File',
        FILE_UPLOAD_BUTTON_ICON: 'file_open',
        FILE_UPLOAD_ACCEPT: '.csv',
        FILE_UPLOAD_VALIDATION_MESSAGE: 'File is required.',
        INVALID_FILE_TYPE_MESSAGE: 'Only CSV files are allowed.',
        INVALID_FILE_SIZE_MESSAGE: 'File size must not exceed 1 MB.'

    }
    public static cancelModal = {
        CANCEL_MODAL_TITLE: 'Confirm Cancellation',
        CANCEL_MODAL_DESCRIPTION: 'If you cancel, all unsaved information will be lost. Please confirm that you want to cancel.',
        CANCEL_MODAL_CONFIRM_BUTTON_LABEL: 'Confirm',
        CANCEL_MODAL_CANCEL_BUTTON_LABEL: 'Go Back'
    }

}